<template>
    <div class="select_address_box">
        <div class="header">
            <div class="search_header">
                <form
                    action="javascript:return true">
                    <Search
                        v-model.trim="searchValue"
                        @search="getList"
                        shape="round"
                        show-action
                        placeholder="请输入搜索地址">
                        <template #left-icon>
                            <div class="left_icon">
                                <img
                                    :src="require('@img/icon_search_grey.png')"
                                    alt="">
                            </div>
                        </template>
                        <template #action>
                            <div
                                :class="{'active': searchValue.length}"
                                @click="getList"
                                class="btn">
                                搜索
                            </div>
                        </template>
                    </Search>
                </form>
            </div>
        </div>
        <div
            v-if="suggestion.length>0"
            class="list_address scroll">
            <List
                :finished="finished"
                v-model="loading"
                @load="onLoad"
                finished-text="没有更多了">
                <div
                    v-for="(item,index) in suggestion"
                    :key="index"
                    @click="backAddress(item,index)"
                    class="address_item">
                    <div class="left">
                        <div class="left_icon">
                            <!-- v-if="item.title.indexOf(searchValue)>=0" -->
                            <img
                                v-if="index===0"
                                src="./position.png"
                                alt="">
                            <div
                                v-else
                                class="icon_circle" />
                        </div>
                        <div class="left_info">
                            <div
                                v-html="transform_hignlight(item.title)"
                                class="left_title" />
                            <div class="left_txt">
                                {{ item.address }}
                            </div>
                        </div>
                    </div>

                    <!-- <div
                        v-show="latInf"
                        class="right">
                        <div class="right_num">{{getDis(item.location.lat,item.location.lng)+'km' }}</div>
                        <div class="right_num">
                            {{ (item.dis)+'km' }}
                        </div>
                    </div> -->
                </div>
                <Divider />
            </List>
        </div>
    </div>
</template>

<script>
/**
 *@description 搜索地址
 *@Date 2020-12-08
 *@author guili
 */
import QQMapWX from '@/assets/tencent_map/qqmap-wx-jssdk';
// import store from '@/store';
import { Divider, List, Search } from 'vant';
import { mapMutations } from 'vuex';

let qqmapsdk;

export default {
    name: 'SelectAddress',
    components: { Divider, List, Search },
    data() {
        return {
            // cityName: this.$positionCity.cityName ,
            searchValue: '',
            oldValue: '',
            suggestion: [],
            finished: false,
            loading: false,
            finText: '没有更多了',
            currentPage: 0,
            city: '',
            latInf: {
                latitude: '',
                longitude: '',
            },
        };
    },
    created() {
        // 实例化qq map API核心类
        qqmapsdk = new QQMapWX({
            // key: 'YREBZ-C2GW6-YJBSD-MJVCL-AX6ST-AOBVR'
            key: 'CMBBZ-JHIR4-YTKUD-DBOHZ-NQ5X6-2IFWK',
        });
    },
    methods: {
        ...mapMutations('consignee', [
            'addAddressInfo',
        ]),
        onLoad() {
            if (this.loading) {
                setTimeout(() => {
                    if (this.finished || !this.currentPage) return;
                    this.getList();
                }, 500);
            }
        },
        async getList() {
            if (this.searchValue !== this.oldValue) {
                this.suggestion = [];
                this.currentPage = 0;
                this.finished = false;
                this.oldValue = this.searchValue;
            }

            qqmapsdk.getSuggestion({
                // 获取输入框值并设置keyword参数
                keyword: this.searchValue, // 用户输入的关键词，可设置固定值,如keyword:'KFC'
                // region: this.cityName, //设置城市名，限制关键词所示的地域范围，非必填参数
                // region: '北京',
                page_index: ++this.currentPage,
                page_size: 20,
                success: (res) => {
                    // 搜索成功后的回调
                    const { data: sug = [] } = res;

                    this.suggestion = this.suggestion.concat(sug); // 数据拼接
                    // this.suggestion = this.getDis(this.suggestion); // 数据排序
                    this.loading = false;
                    if (this.suggestion.length >= res.count) {
                        this.finished = true;
                    }
                },
                fail: () => this.$errMessage('请求超时，请检查网络状态。'),
            });
        },
        backAddress(item, index) {
            item.index = index;
            item.searchValue = this.searchValue;
            this.addAddressInfo({ ...item });

            // this.$router.push({ path: '/add-address' }); // , query:item
            this.$router.go(-1);
        },
        // getDis(lat, lng) {
        //     return this.getDistance(this.latInf.latitude, this.latInf.longitude, lat, lng);
        // },
        // 计算经纬度
        // getDistance(lat1, lng1, lat2, lng2) {
        //     const radLat1 = (lat1 * (Math.PI)) / 180.0;
        //     const radLat2 = (lat2 * (Math.PI)) / 180.0;
        //     const a = radLat1 - radLat2;
        //     const b = (lng1 * (Math.PI)) / 180.0 - (lng2 * (Math.PI)) / 180.0;
        //     let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2)
        //         + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
        //     s *= 6378.137;// EARH_RADIUS;
        //     s = Math.round(s * 10000) / 10000;
        //     return s > 1 ? Math.round(s) : s;
        // },
        // getDis(sug) {
        //     for (let i = 0; i < sug.length; i++) {
        //         sug[i].dis = this.getDistance(
        //             this.latInf.latitude,
        //             this.latInf.longitude,
        //             sug[i].location.lat,
        //             sug[i].location.lng,
        //         );
        //     }
        //     sug.sort((a, b) => {
        //         if (a.dis < b.dis) {
        //             return -1;
        //         } if (a.dis === b.dis) {
        //             return 0;
        //         }
        //         return 1;
        //     });
        //     return sug;
        // },
        clear() {
            this.searchValue = '';
        },
        transform_hignlight(str) {
            if (this.searchValue) {
                const replaceReg = new RegExp(this.searchValue, 'g');
                // 高亮替换v-html值
                const replaceString = `<span class="search_text" style="color: #3679F0;">${this.searchValue}</span>`;
                // 开始替换
                const titleString = str.replace(replaceReg, replaceString);

                return titleString;
            }
            return str;
        },
    },
};
</script>

<style scoped type="text/css" lang="scss">
.select_address_box {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
    /deep/ .van-divider {
        margin: 0;
    }
    /deep/ .van-field__value {
        padding-right: 4px;
    }
    .header {
        display: flex;
        width: 100%;
        height: 44px;
        line-height: 28px;
        padding: 8px 12px;
        background: #fff;
        .city {
            padding-left: 6px;
            padding-right: 11px;
            span {
                font-size: 14px;
                color: #62636c;
                margin-right: 4px;
                font-weight: 400;
            }
            img {
                width: 10px;
                height: 10px;
                margin-top: 9px;
            }
        }
        .search_header {
            width: 100%;
            .van-cell {
                padding: 0;
                line-height: 28px;
                font-size: 14px;
            }

            .van-search {
                padding: 5px 18px;
            }
            .left_icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
            /deep/ .van-field__control {
                font-weight: 400;
            }
            /deep/ .van-field__left-icon {
                display: flex;
            }
            .btn {
                color: #3679f0;
            }
        }
    }
    .list_address {
        background: #fff;
        margin: 8px 0;
        padding-left: 18px;

        .address_item {
            padding: 12px 18px 12px 0;
            display: flex;
            justify-content: space-between;
            position: relative;
            .left {
                display: flex;
                .left_icon {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    margin-right: 10px;
                    img {
                        width: 18px;
                        height: 18px;
                        margin-left: -8px;
                    }
                    .icon_circle {
                        width: 6px;
                        height: 6px;
                        border-radius: 100px;
                        border: 1px solid #e5e5e5;
                        margin-left: -2px;
                    }
                }
                .left_title {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;
                    line-height: 18px;
                }
                .hot_title {
                    color: #3679f0;
                }
                .left_txt {
                    font-size: 12px;
                    font-weight: 400;
                    color: #90949e;
                    line-height: 12px;
                }
            }
            .right {
                .right_num {
                    font-size: 12px;
                    font-weight: 500;
                    color: #90949e;
                    line-height: 12px;
                }
            }
        }
        .address_item::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            transform: scaleY(0.5);
            height: 1px;
            background: #ddd;
        }
    }
    .scroll {
        flex: 1;
        overflow-y: scroll;
    }
}
</style>
